@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  color: #282c31;
  background-color: #7f869225;
}

h1 {
  font-size: clamp(2.441rem, 2.2393rem + 1.0084vw, 3.815rem);
  line-height: 1.1;
}

h2 {
  font-size: clamp(1.563rem, 1.4341rem + 0.6444vw, 2.441rem);
  line-height: 1.3;
}

h4 {
  font-size: clamp(1rem, 0.9174rem + 0.4132vw, 1.563rem);
  line-height: 1.3;
}

p {
  font-size: clamp(1rem, 0.956rem + 0.2202vw, 1.3rem);
  line-height: 1.3;
}

h3 {
  font-size: clamp(1.25rem, 1.1472rem + 0.5138vw, 1.95rem);
  line-height: 1.3;
}
